import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileLogo, mobileHero, mobileHeroText } from 'images'
import ViewMenuButton from './ViewMenuButton'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="90vh"
          maxHeight="790px"
          w="100%"
          image={`url(${mobileHero}) top / cover no-repeat`}
          column
          justifyBetween
          alignCenter
        >
          <CFView b w="100%" column center pv="10px">
            <CFImage src={mobileLogo} w="70%" maxWidth="350px" alt="About" />
          </CFView>
          <CFView w="100%" textCenter mb="35px">
            <CFView pulsate textCenter w="100%">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView row center bg="rgba(0,0,0,.8)" ph="60px" pv="2px">
            <CFView h2 bold white style={{ fontSize: 28 }}>
              Welcome to Crescent Sushi (Surrey)!
            </CFView>
            <CFView row center mt="5px">
              <CFView ml="30px" pulsate>
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
